import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import store from "@/store";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { INITIATION_METHODS } from "@connectpath/common";
const uuidv4 = require("uuid/v4");

class ActivityFactory {
  constructor(contact) {
    if (ActivityFactory.exists) return ActivityFactory.instance;
    ActivityFactory.instance = this;
    ActivityFactory.exists = true;
  }

  constructReferenceArray(references) {
    if (!references) return null;

    let refs = [];
    for (let r in references) {
      refs.push({
        Name: r,
        Type: references[r].type,
        Value: references[r].value,
      });
    }
    return refs;
  }

  transform(contact) {
    let _activity = {
      Attributes: JSON.stringify(contact.attributes || {}),
      Channel: contact.type,
      Connections: contact.connections,
      References: this.constructReferenceArray(contact.summary?.References),
      InitiationMethod: contact.isInbound
        ? "INBOUND"
        : contact.InitiationMethod == "FAILED"
        ? contact.InitiationMethod
        : "OUTBOUND",
      InitiationTimestamp: contact.summary?.InitiationTimestamp,
      InstanceId: contact.summary.InstanceId,
      Username: contact.summary.Username,
      Queue: contact.summary.Queue,
      QueueDetails: { ARN: contact.summary.QueueArn },
      id: contact.contactId,
      DisconnectTimestamp: contact.DisconnectTimestamp,
      Source: contact.Source,
    };
    if (contact.summary?.CannedScriptUsed) {
      _activity.CannedScriptUsed = contact.summary?.CannedScriptUsed;
    }

    switch (contact.type) {
      case "voice":
        _activity.CustomerEndpoint = contact.phoneNumber;
        _activity.MOS = contact.summary.MOS;
        if (contact.state.type === "error" && contact.isInbound) {
          _activity.InitiationTimestamp = getTimeObject().offsetToISOString();
          if (contact.externalCallStatus === "disconnected") {
            _activity.InitiationMethod = INITIATION_METHODS.ABANDONED_AGENT;
          } else {
            _activity.id = uuidv4();
            _activity.InitialContactId = _activity.id;
            _activity.InitiationMethod = contact.rejected ? INITIATION_METHODS.REJECTED : INITIATION_METHODS.MISSED;
          }
        }
        break;
      case "queue_callback":
        _activity.CustomerEndpoint = contact.phoneNumber;
        _activity.MOS = contact.summary.MOS;
        if (contact.state.type === "error" && contact.isInbound) {
          _activity.InitiationTimestamp = getTimeObject().offsetToISOString();
          if (contact.externalCallStatus === "disconnected") {
            _activity.InitiationMethod = INITIATION_METHODS.ABANDONED_AGENT;
          } else {
            _activity.id = uuidv4();
            _activity.InitialContactId = _activity.id;
            _activity.InitiationMethod = contact.rejected ? INITIATION_METHODS.REJECTED : INITIATION_METHODS.MISSED;
          }
        }
        break;
      case "sms":
        _activity.CustomerEndpoint = contact.attributes.From.value;
        _activity.SystemEndpoint = contact.attributes.To.value;
        _activity.QueueDetails = {
          Name: contact?.data?.queue?.name,
          ARN: contact?.data?.queue?.queueARN,
        };
        _activity.DisconnectTimestamp = contact.DisconnectTimestamp;
        break;
      case "email":
        _activity.CustomerEndpoint = contact.attributes.From.value;
        _activity.SystemEndpoint = contact.attributes.To.value;
        _activity.InitiationMethod = contact.InitiationMethod;
        _activity.id =
          _activity.InitiationMethod == "MISSED" || _activity.InitiationMethod == "REJECTED" ? uuidv4() : _activity.id;
        _activity.QueueDetails = {
          Name: contact?.data?.queue?.name,
          ARN: contact?.data?.queue?.queueARN,
          EnqueueTimestamp: contact?.data?.queueTimestamp.toISOString(),
          DequeueTimestamp: getTimeObject().offsetToISOString(),
          Duration: Math.round((getTimeObject().offsetGetTime() - new Date(contact?.data?.queueTimestamp).getTime()) / 1000),
        };
        _activity.DisconnectTimestamp =
          _activity.InitiationMethod == "MISSED" || _activity.InitiationMethod == "REJECTED"
            ? contact.DisconnectTimestamp
            : null;
        break;
      case "fax":
        _activity.CustomerEndpoint = contact.attributes.From.value;
        _activity.SystemEndpoint = contact.attributes.To.value;
        break;
      case "chat":
        _activity.CustomerEndpoint = contact.customerName;
        if (contact.state.type === "error" && contact.isInbound) {
          _activity.InitialContactId = _activity.id;
          _activity.id = uuidv4();
          _activity.InitiationTimestamp = getTimeObject().offsetToISOString();
          _activity.InitiationMethod = contact.rejected ? "REJECTED" : "MISSED";
        }
        let transcript = store.getters["contact/getChatTranscriptions"][contact.contactId];
        _activity.Transcript = JSON.stringify(transcript);
        break;
      case "task":
        _activity.CustomerEndpoint = contact.data.name;
        _activity.ContactDetails = {
          Name: contact.data.name,
          Description: contact.data.description,
        };

        break;
    }
    return _activity;
  }
}

export default class ActivityService {
  constructor() {
    if (ActivityService.exists) return ActivityService.instance;
    ActivityService.instance = this;
    ActivityService.exists = true;

    this.activityFactory = new ActivityFactory();
  }

  async postActivity(contact) {
    if (contact.summary) {
      let activity = this.activityFactory.transform(contact);
      if (activity.MOS) {
        // if(activity.MOS > 5){
        //   alert('Faulty Entry')
        // }
      }

      if (activity.InitiationMethod === "MISSED" || activity.InitiationMethod === "REJECTED") {
        //these records are now created by the stream handler
        return;
      }

      if (!contact.state.type === "error" && contact.isInbound) delete contact.DisconnectTimestamp;

      delete activity.Transcript;

      const request = await API.graphql(
        graphqlOperation(mutations.createActivity, {
          input: cleanItem(activity),
        })
      );
      store.commit("activity/newListRecentActivity", request.data.createActivity);
    }
  }

  async updateActivity(contact) {
    if (contact.summary) {
      try {
        let activity = this.activityFactory.transform(contact);

        if (activity.MOS) {
          // if(activity.MOS > 5){
          //   alert('Found It')
          // }
        }
        const request = await API.graphql(
          graphqlOperation(mutations.updateActivity, {
            input: cleanItem(activity),
          })
        );
        store.commit("activity/updateListRecentActivity", request.data.updateActivity);
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  }
}

function cleanItem(item) {
  return {
    ...item,
    Recording: safeStringify(item.Recording),
    Recordings: safeStringify(item.Recordings),
    QueueDetails: safeStringify(item.QueueDetails),
    Agent: safeStringify(item.Agent),
    ContactDetails: safeStringify(item.ContactDetails),
  };
}

function safeStringify(value) {
  if (value) {
    try {
      return JSON.stringify(value);
    } catch (err) {
      return value;
    }
  } else return {};
}
